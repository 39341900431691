import React from "react";
import { email, required } from "../../../helpers/form-validation";
import { Row, Col, Button } from "react-bootstrap";
import Input from "react-validation/build/input";
import Select2 from 'react-select'
import "./quote-form.scss";
export const CustomerTopSection = ({ quote, handleChange, endUsersArray, handleEndUserChange, handleOpen }) => {
    return (
        <Row>
            <Col lg="6" className="mb-3">
                <Input
                    validations={[required]}
                    className="input form-control custom-input"
                    type="text"
                    value={quote.BillingFirstName}
                    name="BillingFirstName"
                    onChange={handleChange}
                    placeholder="First Name"
                />
            </Col>
            <Col lg="6" className="mb-3">
                <Input
                    validations={[required]}
                    className="input form-control custom-input"
                    type="text"
                    value={quote.BillingLastName}
                    name="BillingLastName"
                    onChange={handleChange}
                    placeholder="Last Name"
                />
            </Col>
            <Col lg="12" className="mb-3">
                <Input
                    validations={[required, email]}
                    className="input form-control custom-input"
                    type="text"
                    value={quote.CustomerEmail}
                    name="CustomerEmail"
                    onChange={handleChange}
                    placeholder="Email Address"
                />
            </Col>
            <Col lg="6" className="mb-3">
                <Select2
                    name="endUser"
                    onChange={handleEndUserChange}
                    placeholder="Select End User"
                    value = {(endUsersArray && endUsersArray.length) && endUsersArray.find(elem => elem.value === quote.endUserId)}
                    label options={endUsersArray} 
                />
            </Col>
            <Col lg="2" className="mb-3">
            <Button variant="primary" style={{ fontSize: '20px' }} onClick={handleOpen} className="modal-buttons plus-button">
                +
            </Button>
            </Col>
        </Row>
    )
}
