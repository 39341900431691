import React from "react";
// import { LoginAction } from "../../actions/user/login";
import store from "../../../store";
import { connect } from "react-redux";
import { IN_PROCESS, QUOTE, TO_QUOTE_CREATE, TO_QUOTE_LIST } from "../../../helpers/constants";
import Link from "react-router-dom/Link";
// import { MDBSelect } from "mdbreact";
import { email, gt0, numbers, required } from "../../../helpers/form-validation";
import { Button, Row, Col, Table, FormControl, FormLabel, Modal } from "react-bootstrap";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import "./quote-form.scss";
import Select2 from 'react-select'
import Select, { select } from "react-validation/build/select";
import {
	fetchProductOptions,
	GetCountries,
	GetProducts,
	GetQuote,
	SaveExternalQuote,
	SaveEndUser,
	GetEndUsers
} from "../../../actions/quote/quote-actions";
import { getUser, parseFreeAccessoriesForView, toast } from "../../../helpers/utility";
import { BASE_URL } from "../../../api/constants";
import customerTopSection, { CustomerTopSection } from "./customer-top-section";
import {CreateEndUser} from "./create-enduser";
import { E2BIG } from "constants";
// import { timeStamp } from "console";
class QuoteForm extends React.Component {
	constructor(props) {
		super(props);
		this.createEndUserFormRef = React.createRef();
		this.handleChange = this.handleChange.bind(this);
		this.handleOnSubmit = this.handleOnSubmit.bind(this);
		this.sameAsBilling = this.sameAsBilling.bind(this);
		this.sameAsShipping = this.sameAsShipping.bind(this);
		this.body = this.body.bind(this);		
		this.state = {
			quote: {
				Quote_Comments: ''
			},
			options: [],
			selectedProducts: [],
			redirect: "",
			total: 0,
			sameAsBilling: false,
			selectValue: [],
			selectOption:[],
			type: "",
			popupFlag: false,
			href:"",
			CategoryOptions : [],
			showModal: false,
			endUser: {
				firstName: "",
				lastName: "",
				emailAddress: "",
				companyName: "",
				phoneNumber: "",
				BillingAddress1: "",
				BillingAddress2: "",
				City: "",
				PostalCode: "",
				State: "",
				Country: "",
			  },
		};
		// this.emptyProduct = {};
	}
	handleChange(e, index, childIndex) {
		let selectedProducts = this.state.selectedProducts;
		// console.log(index,this.state.selectedProducts);
		let quote = this.state.quote;
		// if (e.target.name === "productList") {
		// 	let productCode = e.target.value;
		// 	let product = this.props.products.find(
		// 		({ ProductCode }) => ProductCode === productCode
		// 	);
		// 	let productChilds = parseFreeAccessoriesForView(
		// 		product,
		// 		this.props.products
		// 	);
		// 	if (productChilds.length > 0) {
		// 		productChilds.forEach((elem) => {
		// 			// elem.ProductPrice = 0
		// 			elem.ProductPrice = 0;
		// 			elem.isChild = 1;
		// 			elem.parent = productCode;
		// 			product.ProductDescriptionShort = product.ProductDescriptionShort ? product.ProductDescriptionShort.replace(/style="[^"]*"/g, "") : '';
		// 		});
		// 	}
		// 	product.Childs = productChilds || [];
		// 	product.Quantity = 1;
		// 	product.parentName = product.ProductCode;
		// 	product.isChild = 0;
		// 	product.ProductPrice = product.ProductPrice;
		// 	product.Discription = product.ProductDescriptionShort ? product.ProductDescriptionShort.replace(/style="[^"]*"/g, "") : '';
		// 	selectedProducts[index] = product;
		// 	this.setState({ selectedProducts });
		// 	this.updateTotal();
		// } else 
		if (e.target.name === "Quantity") {
			selectedProducts[index].Quantity = e.target.value;

			this.setState({ selectedProducts });
			this.updateTotal(false);
		} else if (e.target.name === "ProductPrice") {
			// if (!this.props.quote.lineItems) {
			// 	selectedProducts[index].ProductPrice = e.target.value ? e.target.value : 1;
			// } else {
			selectedProducts[index].ProductPrice = e.target.value
			// }
			this.setState({ selectedProducts });
			this.updateTotal(false);
		} else if (e.target.name === "productDescription") {
			selectedProducts[index].Discription = e.target.value
			this.setState({ selectedProducts });
		} else if (e.target.name === "productDescriptionChild") {
			selectedProducts[index].Childs[childIndex].ProductDescriptionShort = e.target.value
			this.setState({ selectedProducts });
		} 
		else {
			quote[e.target.name] = e.target.value;
		}
		// quote.lineItems = selectedProducts;
		this.setState({ quote });
		this.updateTotal(false);
	}
	handleEndUserChange = (e) => {
		const quote = this.state.quote;
		console.log('quote', quote);
		quote.endUserId = e.value;
		this.setState({
			quote: quote
		})
	}
	getAllAccessories = (product, accArray, level) => {
		let accessories = parseFreeAccessoriesForView(product, this.props.products);
		accessories.forEach((accessory) => {
			const accProduct = this.props.products.find((product) => {
				return accessory.ProductCode === product.ProductCode
			})
			if(accProduct){
				if(!accArray.includes(accProduct)){
					accProduct.Qty = accessory.Qty
					accProduct.level = level
					accArray.push(accProduct)
					this.getAllAccessories(accProduct, accArray, level + 1);
				}
			}
		})
		return accArray
	}
	handleSelectChange = (selectValue, index) => {
		// console.log(selectValue, index)
		let productChilds = []
		let selectedProducts = this.state.selectedProducts;
		let selectValueArray = this.state.selectValue
		let productCode = selectValue.label;
		let product = this.props.products.find(
			({ ProductCode }) => ProductCode === productCode
		);
		if(product){

			product = JSON.parse(JSON.stringify(product))
			let productChilds = this.getAllAccessories(product, [], 0)
			// console.log(accessories, "ALL ACCESSORIES");
			// productChilds = parseFreeAccessoriesForView(
			// 	product,
			// 	this.props.products
			// );
			if (productChilds.length > 0) {
				productChilds.forEach((elem) => {
					elem.ProductPrice = 0;
					elem.isChild = 1;
					elem.isCategoriesOption = 0
					elem.parent = productCode;
					// elem.ProductPrice = 0
					product.ProductDescriptionShort = product.ProductDescriptionShort ? product.ProductDescriptionShort.replace(/style="[^"]*"/g, "") : '';
				});
			}
			product.Childs = productChilds || [];
			product.Quantity = 1;
			product.parentName = product.ProductCode;
			product.isChild = 0;
			product.ProductPrice = product.ProductPrice;
			product.isCategoriesOption = 0
			product.Discription = product.ProductDescriptionShort ? product.ProductDescriptionShort.replace(/style="[^"]*"/g, "") : '';
			selectedProducts[index] = product;
			selectValueArray[index] = selectValue
			this.setState({ selectedProducts, selectValue: selectValueArray });
			this.updateTotal(false);
			store.dispatch(fetchProductOptions(product.OptionIDs,index));
		}
	}
	randomString = () => {
		return Math.random().toString(36).substring(6);
	}
	handleSelectOptionChange = (selectValue, index,productIndex) => {
		console.log(selectValue,"yoo")
		let selectedProducts = this.state.selectedProducts;
		let selectValueArray = this.state.selectOption
		let productCode = selectValue?.value?.optionsDesc;
		let product = this.props.products.find(
			({ ProductCode }) => ProductCode === productCode
		);
		if(product){
			product = JSON.parse(JSON.stringify(product))
			product.categoryIdOfOption = selectedProducts[productIndex].availableOptions[index].options[0].optionCatId
			product.Quantity = 1;
			product.isChild = 0;
			product.parent = selectedProducts[productIndex].ProductCode;
			product.isCategoriesOption = 1
			product.ProductPrice = selectValue?.value?.isProductPrice  ? product.ProductPrice : 0;
			product.Discription = product.ProductDescriptionShort ? product.ProductDescriptionShort.replace(/style="[^"]*"/g, "") : '';
			selectedProducts[productIndex].SelectedOption[index] = product;
			selectValueArray[selectValue.category] = selectValue
			this.setState({ selectedProducts, selectOption: selectValueArray });
			this.updateTotal(true,productIndex);
		}
		
	}
	handleOnSubmit(e,flag) {
		e.preventDefault();
		console.log("hello")
		this.form.validateAll();
		if (this.form.getChildContext()._errors.length === 0) {
			var lineItems = [];
			if(this.state.selectedProducts.length &&  this.state.selectedProducts[0].ProductCode && this.state.selectedProducts[0].ProductCode !== "undefined" && this.state.selectedProducts[0].ProductCode !== null && this.state.selectedProducts[0].ProductCode !== ""){
				for (var i = 0; i < this.state.selectedProducts.length; i++) {
					if (this.state.selectedProducts[i].ProductName != undefined)
						lineItems.push({
							Quantity: this.state.selectedProducts[i].Quantity,
							ProductName: this.state.selectedProducts[i].ProductName.replace(
								/'/g,
								"\\'"
							).replace(/"/g, '\\"'),
							Discription: this.state.selectedProducts[i].Discription
								.replace(/'/g, "\\'")
								.replace(/"/g, '\\"'),
							ProductCode: this.state.selectedProducts[i].ProductCode,
							isChild: this.state.selectedProducts[i].isChild,
							isCategoriesOption :  this.state.selectedProducts[i].isCategoriesOption,
							ProductPrice: this.state.selectedProducts[i].isChild ? 0 : parseFloat(this.state.selectedProducts[i].ProductPrice),
							DiscountValue: 0,
							TaxableProduct: 1,
							parent: this.state.selectedProducts[i].parent
								? this.state.selectedProducts[i].parent
								: "",
							parentName: this.state.selectedProducts[i].parentName
								? this.state.selectedProducts[i].parentName
								: "",
						});
					if(this.state.selectedProducts[i].SelectedOption && this.state.selectedProducts[i].SelectedOption.length){
						this.state.selectedProducts[i].SelectedOption.forEach((option) => {
							if (this.state.quote.QuoteNo) {
								option.QuoteNo = this.state.quote.QuoteNo;
							}
							lineItems.push({
								Quantity: this.state.selectedProducts[i].Quantity,
								ProductName: option.ProductName.replace(/'/g, "\\'").replace(
									/"/g,
									'\\"'
								),
								Discription: option.ProductDescriptionShort? option.ProductDescriptionShort.replace(
									/'/g,
									"\\'"
								).replace(/"/g, '\\"'): "",
								ProductCode: option.ProductCode,
								isChild: option.isChild,
								isCategoriesOption : option.isCategoriesOption,
								ProductPrice: option.isChild ? 0 : parseFloat(option.ProductPrice),
								DiscountValue: 0,
								TaxableProduct: option.isChild ? 0 : 1,
								parent: option.parent ? option.parent : "",
								parentName: option.parentName ? option.parentName : "",
								QuoteNo: option.QuoteNo,
								categoryIdOfOption: option.categoryIdOfOption
							});
						})
						
					}
					if (
						this.state.selectedProducts[i].Childs &&
						this.state.selectedProducts[i].Childs.length
					)
						this.state.selectedProducts[i].Childs.forEach((child) => {
							if (this.state.quote.QuoteNo) {
								child.QuoteNo = this.state.quote.QuoteNo;
							}
							lineItems.push({
								Quantity: parseInt(child.Qty) * this.state.selectedProducts[i].Quantity,
								ProductName: child.ProductName.replace(/'/g, "\\'").replace(
									/"/g,
									'\\"'
								),
								Discription: child.ProductDescriptionShort.replace(
									/'/g,
									"\\'"
								).replace(/"/g, '\\"'),
								ProductCode: child.ProductCode,
								isChild: child.isChild,
								isCategoriesOption: child.isCategoriesOption,
								ProductPrice: child.isChild ? 0 : parseFloat(child.ProductPrice),
								DiscountValue: 0,
								TaxableProduct: child.isChild ? 0 : 1,
								parent: child.parent ? child.parent : "",
								parentName: child.parentName ? child.parentName : "",
								QuoteNo: child.QuoteNo
							});
						});
				}
	
				this.setState(
					{
						quote: {
							...this.state.quote,
							ShipFirstName:this.state.quote.BillingFirstName,
							ShipLastName:this.state.quote.BillingLastName,
							BillingEmailAddress:this.state.quote.CustomerEmail,
							ShipEmailAddress:this.state.quote.CustomerEmail,
							lineItems,
							createdBy: getUser().id,
							modBy: null,
							Quote_Comments: this.state.quote.Quote_Comments ? "<span>" + this.replaceNewline(this.state.quote.Quote_Comments) + "</span>" : '',
							QuoteNotes: "",
							ShippingMethodID: 0,
							TotalShippingCost: 0,
							SalesTaxRate1: 0,
							InsuranceValue: 0,
							SelectedCustomerDiscount: false,
							QuoteDate: null,
							isApproved: 0,
							modifiedOn: null,
							IsCustomerEmailShow: 1,
							IsCustomerNameShow: 1,
							isSendEmail:flag==='email'?flag==='email':false
						},
					},
					() => {
						console.log(this.state.quote)
						store.dispatch(SaveExternalQuote(this.state.quote)); 	//set in session storage
						// if(flag==='email'){
						// 	this.setState({popupFlag:true})
						// 	if(this.props.match.params.QuoteId)
						// 	this.setState({href:'mailto:?subject='+this.props.match.params.QuoteId+ "/" +this.state.quote.ShipCompanyName +'&body='+this.body(this.props.match.params.QuoteId)},()=> {
						// 		if(this.state.popupFlag){
						// 			window.open(this.state.href)
						// 			console.log(this.props.match.params.QuoteId)
						// 		}
						// 	})
	
						// }else{
						// 	this.setState({popupFlag:false})
	
						// }
					}
				);
			}else{
				toast("Please select a Product","error")
			}
		}else {
			console.log('form vali error', this.form.getChildContext()._errors);
		}
	}
	handleAddRowClick = (e) => {
		this.setState(
			{
				selectedProducts: [...this.state.selectedProducts, this.emptyProduct()],
			},
			() => this.updateTotal(false)
		);
	};
	replaceNewline = (val) => {
		if (val !== '') {
			return val.replace(/(?:\r\n|\r|\n)/g, '<br>');
		}

	}
	handlerRemoveRowClick = (e, index) => {
		this.setState(
			{
				selectedProducts: [
					...this.state.selectedProducts.slice(0, index),
					...this.state.selectedProducts.slice(index + 1),
				],
			},
			() => this.updateTotal(false)
		);
	};
	sameAsShipping(e) {
		if (e.target.checked === true) {
			let quote = this.state.quote;
			this.setState({ sameAsBilling: !this.state.sameAsBilling }, () => {
				this.setState({
					quote: {
						...quote,
						...{
							BillingAddress1: quote.ShipAddress1,
							BillingAddress2: quote.ShipAddress2,
							BillingCompanyName: quote.ShipCompanyName,
							BillingCity: quote.ShipCity,
							BillingCountry: quote.ShipCountry,
							BillingPostalCode: quote.ShipPostalCode,
							BillingState: quote.ShipState,
							BillingPhoneNumber: quote.ShipPhoneNumber,
						},
					},
				});
			});
		}
	}
	sameAsBilling(e) {
		if (e.target.checked === true) {
			let quote = this.state.quote;
			this.setState({ sameAsBilling: !this.state.sameAsBilling }, () => {
				this.setState({
					quote: {
						...quote,
						...{
							ShipAddress1: quote.BillingAddress1,
							ShipAddress2: quote.BillingAddress2,
							ShipCompanyName: quote.BillingCompanyName,
							ShipCity: quote.BillingCity,
							ShipCountry: quote.BillingCountry,
							ShipPostalCode: quote.BillingPostalCode,
							ShipState: quote.BillingState,
							ShipPhoneNumber: quote.BillingPhoneNumber,
						},
					},
				});
			});
		}
	}
	emptyProduct = () => {
		return {
			ProductCode: "",
			Quantity: 1,
			ProductPrice: 0,
			ProductDescriptionShort: "",
			Childs: [],
		};
		// return this
	};
	updateTotal = (check,index) => {

		let total = this.state.selectedProducts.reduce(function (a, b) {
			return a + b["ProductPrice"] * b["Quantity"];
		}, 0)
		for (let index = 0; index < this.state.selectedProducts.length; index++) {
			if(this.state.selectedProducts[index].SelectedOption && this.state.selectedProducts[index].SelectedOption.length > 0){
				for (let j = 0; j < this.state.selectedProducts[index].SelectedOption.length; j++) {
					// console.log(this.state.selectedProducts[index].SelectedOption[j].ProductPrice * this.state.selectedProducts[index].SelectedOption[j].Quantity)
					if(this.state.selectedProducts[index].SelectedOption[j])
					total += (this.state.selectedProducts[index].SelectedOption[j].ProductPrice * this.state.selectedProducts[index].SelectedOption[j].Quantity) 
				}
			}
			
			// for (const key in this.state.selectedProducts[index].SelectedOption) {
				// if (Object.hasOwnProperty.call(this.state.selectedProducts[index].SelectedOption, key)) {
				// 	total += (this.state.selectedProducts[index].SelectedOption.ProductPrice * this.state.selectedProducts[index].SelectedOption.Quantity) 
				// }
			// }
			
		}
		this.setState({
			total: total
		});
		

	};

	resetForm = () => {
		window.location = BASE_URL + TO_QUOTE_CREATE
	}

	// getCategoriesOfOrder = (product, parentIndex) => {
	// 	if (product.OptionIDs !== "" && product.OptionIDs !== "undefined" && product.OptionIDs !== null) {
	// 		OptionService.categoriesOptionForProduct(product.OptionIDs).then(function (response) {
	// 			product.availableOptions = response.data.result
	// 			product.CategoryOptions.forEach(catOption => {
	// 				product.availableOptions.forEach((elem, ind) => {
	// 					elem.options.forEach(element => {
	// 						if (catOption.ProductCode === element.optionsDesc) {
	// 							product.availableOptions[ind].selectedCategoriesOption = element
	// 							product.availableOptions[ind].productOptionDetails = catOption
	// 							$scope.updateTotals("qty2", parentIndex);
	// 						}
	// 					});
	// 				});
	// 			})
	// 		}, function (error) {
	// 			console.log('serial fetch error', error);
	// 		})
	// 	}
	// }
	pushCategoryOption = () => {
		console.log(this.state.CategoryOptions,"yoo")

		
	}
	componentDidUpdate(prevProps) {
		if (prevProps.products !== this.props.products) {
			let options = this.state.options;
			let ProductCode = ''
			this.props.products.map((product, index) => {
				ProductCode = product.ProductCode
				options.push({ value: ProductCode, index: index })
			})
			this.setState({
				options: options
			})
			this.updateTotal(false)
			
		}
		if (prevProps.options !== this.props.options){
			let selectedProducts = this.state.selectedProducts;
			selectedProducts[this.props.options.index].availableOptions = this.props.options.response
			selectedProducts[this.props.options.index].SelectedOption = []
			let selectValueArray = this.state.selectOption
			if(selectedProducts[this.props.options.index].CategoryOptions){
				selectedProducts[this.props.options.index].CategoryOptions.forEach((catOption,index) => {
					selectedProducts[this.props.options.index].availableOptions.forEach((elem, ind) => {
						elem.options.forEach(element => {
							if (catOption.ProductCode === element.optionsDesc) {
								selectedProducts[this.props.options.index].SelectedOption[ind] = catOption;
								//elem.label = element.optionsDesc
								elem.value = element
								let option = {
									label : element.optionName + "["+ element.optionsDesc+ "]",
									value: element,
									category: elem.label + " " + selectedProducts[this.props.options.index].ProductCode
								}
								this.handleSelectOptionChange(option, ind,this.props.options.index)
							}
						});
					});
				})
			} else {
				selectedProducts[this.props.options.index].availableOptions.forEach((elem, ind) => {
					elem.options.forEach(element => {
						//elem.label = element.optionsDesc
						elem.value = element
						let option = {
							label : element.optionName + "["+ element.optionsDesc+ "]",
							value: element,
							category: elem.label + " " + selectedProducts[this.props.options.index].ProductCode
						}
						if(elem.value.defaultSelected === 1){
							this.handleSelectOptionChange(option, ind,this.props.options.index)
						}
					})
				})
			}
			this.setState({
				selectedProducts, selectOption: selectValueArray 
			},()=>{
				
			})
		}
		if ((JSON.stringify(prevProps.quote) !== JSON.stringify(this.props.quote) || prevProps.products !== this.props.products)) {
			let quote = this.state.quote;
			let CategoryOptions = this.state.CategoryOptions
			if (this.props.match.params.QuoteId) {
				if (this.props.quote.lineItems) {
					let selectValueArray = this.state.selectValue
					let selectedProducts =  this.state.selectedProduct
					selectedProducts = this.props.quote.lineItems.filter(
						(elem) => !elem.isChild && !elem.isCategoriesOption
					);
					CategoryOptions = this.props.quote.lineItems.filter(
						(elem) => !elem.isChild && elem.isCategoriesOption
					);
					selectedProducts.forEach((main,index) => {
						if (this.props.products)
							if (this.props.products.length > 0) {
								main.CategoryOptions = []
								let productChilds = []
								selectValueArray.push({ label: main.parentName, value: main })
								let product = this.props.products.find(
									({ ProductCode }) => ProductCode === main.parentName
								);
								if(product)
								productChilds = this.getAllAccessories(product, [], 0)
								productChilds.forEach((child) => {
									this.props.quote.lineItems.forEach((lineItem) => {
										if(child.ProductCode === lineItem.ProductCode){
											child.ProductDescriptionShort = lineItem.Discription
										}
									})
								})
								if (productChilds.length > 0) {
									productChilds.forEach((elem) => {
										elem.isChild = 1;
										elem.ProductPrice = 0;
										elem.parent = main.ProductCode;
									});
								}
								main.Childs = productChilds || [];
								if(!main.isChild && !main.isCategoriesOption){
									store.dispatch(fetchProductOptions(product.OptionIDs,index));
								}
							}
					});
					this.setState({ selectedProducts,CategoryOptions, quote: this.props.quote, type: "Update", selectValue: selectValueArray }, () =>
						this.state.CategoryOptions.forEach(pc => {
							for (var i = 0; i < this.state.selectedProducts.length; i++) {
								if (pc.parent == this.state.selectedProducts[i].parentName) {
									if(this.state.selectedProducts[i].CategoryOptions){
										this.state.selectedProducts[i].CategoryOptions.push(pc)
									}
								}
							}
						})
					);
				}
			}
			else {
				this.setState({ type: "Submit" })
			}
			this.updateTotal(false)
		}
		if (this.props.quotes) {
			if (!this.props.match.params.QuoteId && prevProps.quotes !== this.props.quotes) {
				window.location = BASE_URL + TO_QUOTE_LIST
				// this.setState({href:'mailto:?subject='+this.props.quotes.quoteNo + "/" +this.state.quote.ShipCompanyName +'&body='+this.body(this.props.quotes.quoteNo)},()=> {
					// if(this.state.popupFlag){
						// window.open(this.state.href)
						// window.location = BASE_URL + TO_QUOTE_LIST
					// }
					// else{
					// 	window.location = BASE_URL + TO_QUOTE_LIST
					// }
				// })
			this.updateTotal(false)

				
		 	}
	   }
	}
	componentDidMount() {
		var type;
		if (this.props.match.params.QuoteId) {
			store.dispatch(GetQuote(this.props.match.params.QuoteId));

			type = "Update"
		}
		else {
			type = "Submit"
		}
		store.dispatch(GetCountries());
		store.dispatch(GetProducts());
		store.dispatch(GetEndUsers())
		this.setState(
			{
				selectedProducts: [...this.state.selectedProducts, this.emptyProduct()],
				type: type
			},
			() => this.updateTotal(false)
		);
	}
	componentWillUnmount() {
		store.dispatch({
			type: QUOTE,
			payload: {}
		});
	}
	  
	orderItemDetails = (selectedProducts) => {
		
		let list = ""
		selectedProducts.map((obj,index) => {
           list += `<tr>
			<td valign="top">${obj.ProductCode}  </td>
			<td valign="top">${obj.ProductName}  </td> 
			<td valign="top">${obj.Quantity} <br/> </td>
			<td valign="top">${obj.ProductPrice}</td>
			<td valign="top">${obj.ProductPrice * obj.Quantity}</td>
		    </tr>`;

				obj.Childs.map((objChild)=> { 
					list += `<tr>
					<td valign="top">${objChild.ProductCode}    </td>
					<td valign="top">${objChild.ProductName}    </td> 
					<td valign="top">${objChild.Quantity * obj.Quantity}  </td>
				
			
		    		</tr>`;
				
				});
		});
			return list;
	}
	roundTo2Decimals = (number = 0.00) => {
		console.log("yooo",Math.round((number + Number.EPSILON) * 100) / 100)
		return Math.round((number + Number.EPSILON) * 100) / 100
	}
	body(){
		let quoteId
		if(this.props.match.params.QuoteId){
			 quoteId = this.props.match.params.QuoteId
		}
		else if(this.props.quotes.quoteNo){
			quoteId = this.props.quotes.quoteNo
		}
		else{
			quoteId = 0
		}
		let total = this.roundTo2Decimals(this.state.total)
		console.log(total)
		return encodeURIComponent(
			
		`<div>
		<table width="650" border="1" cellspacing="0" cellpadding="20" bgcolor="#FFFFFF" align="center">
		 <tbody><tr>
		  <td>
		   <table width="100%" border="0" cellspacing="1" cellpadding="5">
			<tbody><tr valign="top">
			     <td align="left">	<b>Quote Number:</b> ${quoteId} </td> 
				 <td align="right"><b>Customer Name:</b> ${this.state.quote.BillingFirstName + " " + this.state.quote.BillingLastName}</td>
				</tr>
			 </tbody></table>
			 <br>
		   <br>
		   <table width="100%" border="0" cellspacing="1" cellpadding="5">
			 <tbody><tr valign="top"> 
			 <td><b>Bill To:</b><br>
			 ${this.state.quote.BillingCompanyName}<br> ${this.state.quote.BillingFirstName + " " + this.state.quote.BillingLastName}<br>
			 ${this.state.quote.BillingAddress1 +" " + this.state.quote.BillingCity + " " + this.state.quote.BillingState + " " + this.state.quote.BillingPostalCode+ " " + this.state.quote.BillingCountry}<br>
			 ${this.state.quote.BillingPhoneNumber}<br>
			 <a href="mailto:${this.state.quote.CustomerEmail}" target="_blank">${this.state.quote.CustomerEmail}</a><br>
			    <br></td>
				<td><b>Ship To:</b><br>
				${this.state.quote.ShipCompanyName}<br> ${this.state.quote.BillingFirstName + " " + this.state.quote.BillingLastName} <br>
				${this.state.quote.ShipAddress1 +" " + this.state.quote.ShipCity + " " + this.state.quote.ShipState + " " + this.state.quote.ShipPostalCode}
			  <br>
			  ${this.state.quote.ShipCountry} <br>
			  ${this.state.quote.ShipPhoneNumber} <br> </td>
			  </tr>
			  <tr valign="top"> 
			 <td colspan="2"><b>Order Details:</b><br> <br>
              <table width="100%" bgcolor="#EEEEEE">
              <tbody>
              <tr>
                <td style="width: 18%"><b>Code</b></td>
                <td style="width: 52%"><b>Item / Options </b></td>
                <td style="text-align: right; width: 5%"><b>Quantity</b></td>
                <td style="text-align: right; width: 10%"><b>ProductPrice</b></td>
                <td style="text-align: right; width: 15%"><b>Grand Total</b></td>
              </tr>
			  	 ${this.orderItemDetails(this.state.selectedProducts)}
            </tbody>
			<tr>
                <td  colspan="4" style="text-align: right"><b> Subtotal:</b> </td>
                <td style="text-align: right">$${total}</td>
              </tr>
			  <tr>
                <td colspan="4" style="text-align: right"><b> Grand Total: </b></td>
                <td style="text-align: right">$${total} </td>
              </tr>
			</table>
		</div>	  
		`
		)
		
	}
	handleOpen = () => {
		this.setState({ showModal: true });
	};
	
	handleClose = () => {
		this.setState({ showModal: false });
	};
	
	handleSave = async (e) => {
		e.preventDefault();
		this.createEndUserFormRef.current.validateAll();	  
		if (this.createEndUserFormRef.current.getChildContext()._errors.length === 0) {
		  try {
			await store.dispatch(SaveEndUser(this.state.endUser));
			await store.dispatch(GetEndUsers());
			const newEndUser = this.props.endUsers[this.props.endUsers.length - 1];
			this.handleEndUserChange(newEndUser);
	  		this.handleClose();
		  } catch (error) {
			console.error('Error:', error);
		  }
		}
	  };	  
	handleEndUser = (e) => {
		const { name, value } = e.target;
		this.setState((prevState) => ({
		  endUser: {
			...prevState.endUser,
			[name]: value,
		  },
		}));
	  };
	render() {
		const customStyles = {
			control: base => ({
				...base,
				height: 35,
				minHeight: 35
			})
		};

		return (
			<div className="quote-form">
				<div className="container">
					<Col lg="12" className="mb-3">
						<h3>{this.state.type} Quote</h3>
						<p className="text-muted"> Submit Quote to IntrepidCS</p>
					</Col>
					<div className="col-lg-12 mx-auto">
						<Form
							ref={(c) => {
								this.form = c;
							}}>
							<CustomerTopSection handleChange={this.handleChange} quote={this.state.quote} endUsersArray={(this.props.endUsers)} handleEndUserChange={this.handleEndUserChange} handleOpen={this.handleOpen}/>
							{/* <CustomerAddressSection handleChange={this.handleChange} quote={this.state.quote} sameAsShipping={this.sameAsShipping} sameAsBilling={this.sameAsBilling} countries={this.props.countries} ></CustomerAddressSection> */}
							<Modal show={this.state.showModal} onHide={this.handleClose} centered bsSize="lg">
								<Modal.Header closeButton>
									<Modal.Title>Create End User</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<CreateEndUser ref={this.createEndUserFormRef} handleChange={this.handleEndUser}/>
								</Modal.Body>
								<Modal.Footer>
									<div className="d-flex justify-content-between">
										<Button variant="secondary" onClick={this.handleClose} className="modal-buttons cancel-button">
											Cancel
										</Button>
										<Button variant="primary" onClick={this.handleSave} className="modal-buttons">
											Save
										</Button>
									</div>
								</Modal.Footer>
							</Modal>
							<Row>
								<Col lg="6" className="mb-2">
									<Row>
										<Col lg="12" className="mb-2">
											<h6>Billing Address</h6>
											<label htmlFor="sameAsShipping">
												{" "}
												<input
													onChange={this.sameAsShipping}
													id="sameAsShipping"
													name="sameAsShipping"
													type="checkbox"></input>{" "}
												Same as shipping{" "}
											</label>
										</Col>

										<Col lg="12" className="mb-3">
											<Input
												validations={[required]}
												className="input form-control custom-input"
												type="text"
												value={this.state.quote.BillingAddress1}
												name="BillingAddress1"
												onChange={this.handleChange}
												placeholder="Address 1"
											/>
										</Col>
										<Col lg="12" className="mb-3">
											<Input
												validations={[]}
												className="input form-control custom-input"
												type="text"
												value={this.state.quote.BillingAddress2}
												name="BillingAddress2"
												onChange={this.handleChange}
												placeholder="Address 2"
											/>
										</Col>
										<Col lg="12" className="mb-3">
											<Input
												validations={[required]}
												className="input form-control custom-input"
												type="text"
												value={this.state.quote.BillingCompanyName}
												name="BillingCompanyName"
												onChange={this.handleChange}
												placeholder="Company"
											/>
										</Col>
										<Col lg="12" className="mb-3">
											<Input
												validations={[required]}
												className="input form-control custom-input"
												type="text"
												value={this.state.quote.BillingCity}
												name="BillingCity"
												onChange={this.handleChange}
												placeholder="City"
											/>
										</Col>
										<Col lg="12" className="mb-3">
											<Input
												validations={[required]}
												className="input form-control custom-input"
												type="text"
												value={this.state.quote.BillingState}
												name="BillingState"
												onChange={this.handleChange}
												placeholder="State/Province"
											/>
										</Col>
										<Col lg="12" className="mb-3">
											<Input
												validations={[required]}
												className="input form-control custom-input"
												type="text"
												value={this.state.quote.BillingPostalCode}
												name="BillingPostalCode"
												onChange={this.handleChange}
												placeholder="Postal Code"
											/>
										</Col>

										<Col lg="12" className="mb-3">
											<Select
												name="BillingCountry"
												validations={[required]}
												onChange={this.handleChange}
												value={this.state.quote.BillingCountry}
												id="BillingCountry"
												placeholder="Country"
												className="form-control">
												<option value="">-- Select Country --</option>
												{this.props.countries &&
													this.props.countries.map((country) => {
														return (
															<option key={country.code} value={country.code}>
																{country.name}
															</option>
														);
													})}
											</Select>
										</Col>

										<Col lg="12" className="mb-3">
											<Input
												validations={[required]}
												className="input form-control custom-input"
												type="text"
												value={this.state.quote.BillingPhoneNumber}
												name="BillingPhoneNumber"
												onChange={this.handleChange}
												placeholder="Phone Number"
											/>
										</Col>
									</Row>
								</Col>
								<Col lg="6" className="mb-2">
									<Row>
										<Col lg="12" className="mb-2">
											<h6>Shipping Address</h6>
											<label htmlFor="sameAsBilling">
												{" "}
												<input
													onChange={this.sameAsBilling}
													name="sameAsBilling"
													id="sameAsBilling"
													value={this.state.sameAsBilling}
													type="checkbox"></input>{" "}
												Same as billing{" "}
											</label>
										</Col>
										<Col lg="12" className="mb-3">
											<Input
												validations={[required]}
												className="input form-control custom-input"
												type="text"
												value={this.state.quote.ShipAddress1}
												name="ShipAddress1"
												onChange={this.handleChange}
												placeholder="Address 1"
											/>
										</Col>
										<Col lg="12" className="mb-3">
											<Input
												validations={[]}
												className="input form-control custom-input"
												type="text"
												value={this.state.quote.ShipAddress2}
												name="ShipAddress2"
												onChange={this.handleChange}
												placeholder="Address 2"
											/>
										</Col>
										<Col lg="12" className="mb-3">
											<Input
												validations={[required]}
												className="input form-control custom-input"
												type="text"
												value={this.state.quote.ShipCompanyName}
												name="ShipCompanyName"
												onChange={this.handleChange}
												placeholder="Company"
											/>
										</Col>
										<Col lg="12" className="mb-3">
											<Input
												validations={[required]}
												className="input form-control custom-input"
												type="text"
												value={this.state.quote.ShipCity}
												name="ShipCity"
												onChange={this.handleChange}
												placeholder="City"
											/>
										</Col>
										<Col lg="12" className="mb-3">
											<Input
												validations={[required]}
												className="input form-control custom-input"
												type="text"
												value={this.state.quote.ShipState}
												name="ShipState"
												onChange={this.handleChange}
												placeholder="State/Province"
											/>
										</Col>
										<Col lg="12" className="mb-3">
											<Input
												validations={[required]}
												className="input form-control custom-input"
												type="text"
												value={this.state.quote.ShipPostalCode}
												name="ShipPostalCode"
												onChange={this.handleChange}
												placeholder="Postal Code"
											/>
										</Col>
										<Col lg="12" className="mb-3">
											<Select
												name="ShipCountry"
												onChange={this.handleChange}
												validations={[required]}
												value={this.state.quote.ShipCountry}
												id="ShipCountry"
												placeholder="Country"
												className="form-control">
												<option value="">-- Select Country --</option>
												{this.props.countries &&
													this.props.countries.map((country) => {
														return (
															<option key={country.code} value={country.code}>
																{country.name}
															</option>
														);
													})}
											</Select>
										</Col>
										<Col lg="12" className="mb-3">
											<Input
												validations={[required]}
												className="input form-control custom-input"
												type="text"
												value={this.state.quote.ShipPhoneNumber}
												name="ShipPhoneNumber"
												onChange={this.handleChange}
												placeholder="Phone Number"
											/>
										</Col>
									</Row>
								</Col>
								<Col lg="12" className="mb-2">
									<label htmlFor="Quote_Comments">
										Notes:
									</label>
									<FormControl
										as="textarea"
										className="form-control text-small"
										name="Quote_Comments"
										rows={7}
										style={{ fontSize: " 1rem" }}
										cols={50}
										onChange={this.handleChange}
										value={
											this.state.quote.Quote_Comments &&
											this.state.quote.Quote_Comments.replace(/(<([^>]+)>)/gi, "")
										}></FormControl>
								</Col>
							</Row>
							<Row className="mb-5">
								<Table size="sm" responsive>
									<thead>
										<tr>
											<td>
												<strong>Part Number</strong>
											</td>
											<td className="text-center">
												<strong>Description</strong>
											</td>

											<td className="text-center">
												<strong>Quantity</strong>
											</td>
											<td className="text-center">
												<strong>Unit ProductPrice ($)</strong>
											</td>
											<td className="text-right">
												<strong>Totals</strong>
											</td>
											<td className="text-center">
												<strong></strong>
											</td>
										</tr>
									</thead>
									<tbody>
										{this.state.selectedProducts &&
											this.state.selectedProducts.map(
												(selectedProduct, index) => (
													<>
														<tr key={index}>
															<td className="mb-3 tdClassForSelectIssue">
																<Select2
																	name="productList"
																	onChange={(selectValue) => this.handleSelectChange(selectValue, index)}
																	validations={[required]}
																	id="productList"
																	style={{ width: "200px" }}
																	styles={customStyles}
																	placeholder="Product Code" key={index}
																	value={this.state.selectValue[index]} label options={this.props.products && this.props.products.length > 0 && this.props.products.map(elem => { return { label: elem.ProductCode, value: elem } })} />
															</td>
															<td>
																<FormControl
																	as="textarea"
																	className="form-control text-small"
																	name="productDescription"
																	rows={1}
																	cols={50}
																	onChange={(e) => this.handleChange(e, index)}
																	value={
																		selectedProduct.Discription &&
																		selectedProduct.Discription.replace(
																			/<[^>]+>/g,
																			""
																		)
																	}></FormControl>
															</td>

															<td>
																<Input
																	type="textbox"
																	className="form-control"
																	name="Quantity"
																	validations={[required, gt0]}
																	placeholder="Quantity"
																	min={0}
																	value={selectedProduct.Quantity}
																	onChange={(e) => this.handleChange(e, index)}
																/>
															</td>
															<td>
																<FormLabel>
																	{selectedProduct.ProductPrice}
																</FormLabel>
																{/* <Input
																	type="textbox"
																	className="form-control"
																	validations={[required, numbers]}
																	name="ProductPrice"
																	placeholder="ProductPrice"
																	onChange={(e) => this.handleChange(e, index)}
																	value={selectedProduct.ProductPrice}
																/> */}
															</td>
															<td
																className="text-right"
																style={{ verticalAlign: "middle" }}>
																{(
																	selectedProduct.ProductPrice * selectedProduct.Quantity
																).toLocaleString()}
															</td>
															<td>
																<Button
																	size="sm"
																	onClick={(e) =>
																		this.handlerRemoveRowClick(e, index)
																	}>
																	<i className="fa fa-times"></i>
																</Button>
															</td>
														</tr>
														{selectedProduct.availableOptions &&  selectedProduct.availableOptions.map((categoryOption,index2 )=>(<tr key={index2}>
															<td className="mb-3 tdClassForSelectIssue">
																<FormLabel>{categoryOption.label}</FormLabel>
																<Select2
																	name="categoryOptionsList"
																	onChange={(selectCategoryOption) => this.handleSelectOptionChange(selectCategoryOption, index2,index)}
																	id="categoryOptionsList"
																	style={{ width: "200px" }}
																	styles={customStyles}
																	placeholder="Product Code" key={categoryOption.key}
																	value={this.state.selectOption[categoryOption.label + " " + selectedProduct.ProductCode]}
																	label={categoryOption.label} options={categoryOption.options.map(option => { return { label: option.optionName + "["+ option.optionsDesc+ "]" , value: option, category: categoryOption.label  + " " + selectedProduct.ProductCode} })} />
															</td>
															<td>
																<FormControl
																	as="textarea"
																	className="form-control text-small"
																	name="productDescription"
																	rows={1}
																	cols={50}
																	onChange={(e) => this.handleChange(e, index)}
																	value={
																		selectedProduct.SelectedOption && selectedProduct.SelectedOption[index2] &&
																		selectedProduct.SelectedOption[index2].Discription.replace(
																			/<[^>]+>/g,
																			""
																		)
																	}></FormControl>
															</td>
															<td>
															<FormLabel>
															{selectedProduct.Quantity}
															</FormLabel>
															
															</td>
															<td>
																<FormLabel>
																	{selectedProduct.SelectedOption && selectedProduct.SelectedOption[index2] && selectedProduct.SelectedOption[index2].ProductPrice}
																</FormLabel>
															</td>
															<td
																className="text-right"
																style={{ verticalAlign: "middle" }}>
																{selectedProduct.SelectedOption &&  selectedProduct.SelectedOption[index2] && (
																	(selectedProduct.SelectedOption[index2].ProductPrice * selectedProduct.SelectedOption[index2].Quantity)
																).toLocaleString()}
															</td>
														</tr>))}
														{selectedProduct.Childs &&
															selectedProduct.Childs.map(
																(ChildrenOfSelectedProduct, childIndex) => (
																	<tr>
																		<td>
																			{ChildrenOfSelectedProduct.ProductCode}
																		</td>
																		<td>
																			<FormControl
																				as="textarea"
																				className="form-control text-small"
																				name="productDescriptionChild"
																				rows={1}
																				cols={50}
																				onChange={(e) =>
																					this.handleChange(e, index, childIndex)
																				}
																				value={
																					ChildrenOfSelectedProduct.ProductDescriptionShort
																				}></FormControl>
																		</td>
																			
																		<td>
																			{console.log(ChildrenOfSelectedProduct)}
																			{ChildrenOfSelectedProduct.Qty *
																				selectedProduct.Quantity}
																		</td>
																		<td></td>
																		<td></td>
																	</tr>
																)
															)}
													</>
												)
											)}
										<tr>
											<td colSpan="100%" className="text-right">
												<Button size="sm" onClick={this.handleAddRowClick}>
													{" "}
													Add More{" "}
												</Button>
											</td>
										</tr>
									</tbody>
								</Table>
								<Col lg="6" className="mb-2" />
								<Col lg="6" className="mb-2 d-flex justify-content-between">
									<h6>SubTotal:</h6>
									<strong>${this.state.total.toLocaleString()}</strong>
								</Col>
								<Col lg="6" className="mb-2" />
								<Col lg="6" className="mb-2 d-flex justify-content-between">
									<h2> Total:</h2>
									<h2>${this.state.total.toLocaleString()}</h2>
								</Col>
								<Col lg="12" className="text-right  d-flex flex-row-reverse">
								<Button onClick={(e)=>this.handleOnSubmit(e,'email')} size="sm" type="button" className="mw-100 ml-2" variant="success" value="saveAndEmail" >  
										{/* {this.state.type} Save */}
										Save and Email
									</Button>
									<Button onClick={(e) => this.handleOnSubmit(e,'save')} size="sm" type="button" className="mw-100 ml-2" value="save">
										Save
									</Button>
									{!this.props.match.params.QuoteId && <Button size="sm" variant="danger" onClick={this.resetForm} className="mw-100 ml-1">
										Reset 
									</Button>}
								</Col>

							</Row>
						</Form>
					</div>
				</div>
			</div>
		);
	}
}
const stateMap = (state) => {
	return {
		user: state.user,
		products: state.user.products,
		countries: state.user.countries,
		global: state.global,
        options: state.user.options,
		quote: state.user.quote,
		quotes: state.user.quotes,
		endUsers: state.user.endUsers
	};
};
export default connect(stateMap)(QuoteForm);
